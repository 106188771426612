.monitor-box {
    min-height: 500px;
    margin: 20px;
    border: 1px solid #ddd;
}
.mon-left {
    border-right: 1px solid #ddd;
    height: 500px;
}

.mon-right {
    height: 250px;
}

.lz-btn {
    margin-left: 30px;
    margin-top: 20px;
    display: inline-block;
}
.ant-input-group-addon:first-child{
    width: 100px !important;
}
.ant-input-group.ant-input-group-compact > .ant-select-auto-complete .ant-input{
    width: 182px !important;
}
.monitor .ant-form-item{
    margin-bottom: 0px ;
}
.monitor  .ant-col-5{
    margin-top: 4px;
}
.rtmp{
    color: red;
}
.rtmpipt{
    border: 1px solid red;
}